import { Skeleton, Stack } from "@mui/material";

const ChartSkeleton = () => {
    return (
        <Stack spacing={1} padding={10}>
            {Array.from({ length: 10 }).map((_, index) => (
                <Skeleton
                    key={index}
                    width="100%"
                    height={40}
                    animation="wave"
                />
            ))}
        </Stack>
    );
};

export default ChartSkeleton;
