import { Box, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import {
    preprocessMarkdown,
    queryDatabase,
    transformToMessages,
} from "../utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { services } from "../../services";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "../../../../common/components/SharedLayout/SharedLayout";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import animationData from "../icons/ballurh_lottie.json";
import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import "katex/dist/katex.min.css"; // Import KaTeX CSS for math rendering
import remarkGfm from "remark-gfm"; // Import remark-gfm
import { TypingIndicator } from "./TypingIndicator";
import { PredefinedQuestions } from "./PredefinedQuestions";
import ChatInput from "./ChatInput";
import { CircularProgress } from "@mui/material"; // Import the loader component

export const ChatRoomContainer = ({ refetchMessages }) => {
    const { t } = useTranslation();

    const { userData } = useContext(UserContext);

    const [messages, setMessages] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const messagesEndRef = useRef(null);

    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedChatSession, setSelectedChatSession] = useState(
        searchParams.get("chat_session")
    );

    const { data, isFetching } = useQuery({
        queryKey: [
            "chatRoom",
            { chatSession: searchParams.get("chat_session") },
        ],
        queryFn: services.getChatRoom,
        enabled: !!searchParams.get("chat_session"),
    });

    // Clear messages when chat_session is cleared
    useEffect(() => {
        const currentChatSession = searchParams.get("chat_session");

        if (!currentChatSession) {
            // If chat_session is cleared, reset messages
            setMessages([]);
        } else {
            setSelectedChatSession(currentChatSession);
        }
    }, [searchParams]); // Monitor changes in searchParams

    useEffect(() => {
        if (data?.data?.response?.[0]?.chat) {
            setMessages(transformToMessages(data.data.response[0].chat));
        }
    }, [data]);

    const mutation = useMutation(
        ({ question, merchantDetails }) =>
            queryDatabase(question, merchantDetails),
        {
            onMutate: () => {
                setIsTyping(true); // Show typing indicator when the mutation starts
            },
            onSuccess: (data) => {
                setIsTyping(false);
                setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                        sender: "bot",
                        text:
                            JSON.parse(data)?.output ||
                            JSON.parse(data)?.error ||
                            "Failed to get a valid response from the chat app",
                    },
                ]);

                if (!searchParams.get("chat_session")) {
                    refetchMessages();
                    setSelectedChatSession(JSON.parse(data).chat_session);
                    setSearchParams({
                        chat_session: JSON.parse(data).chat_session,
                    });
                }
            },
            onError: (error) => {
                setIsTyping(false);
                console.error("Error fetching data: ", error);
            },
        }
    );

    const handleSendMessage = (message) => {
        setMessages((prevMessages) => [
            ...prevMessages,
            { sender: "user", text: message },
        ]);

        const merchantDetails = {
            merchant_name: userData.merchant.name,
            merchant_id: userData.merchant.merchant_id,
            merchant_key: userData.merchant.id,
            chat_session: searchParams.get("chat_session") || null,
        };

        mutation.mutate({ question: message, merchantDetails });
    };

    // Scroll to the bottom when messages change
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages, isTyping]);

    if (!messages && !isFetching) {
        return (
            <Typography>
                No messages available. Please start a new conversation.
            </Typography>
        );
    }

    return (
        <Stack sx={{ height: "calc(100vh - 90px)", flex: 1 }} padding={4}>
            <Box
                sx={{
                    flex: 1,
                    overflowY: "auto",
                    padding: 2,
                    paddingBottom: 4,
                    borderRadius: "8px",
                    marginBottom: 2,
                    position: "relative", // To position loader over messages
                }}
            >
                {isFetching && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 10,
                        }}
                    >
                        <CircularProgress /> {/* Material-UI Loader */}
                    </Box>
                )}

                {!messages.length && !isFetching && (
                    <Stack
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flex: 1,
                        }}
                        height="57%"
                    >
                        <Lottie
                            animationData={animationData}
                            loop={true}
                            style={{ width: 200, height: 200 }}
                        />
                        <Typography color="#5BCFDE" variant="h5">
                            {t("chat.aiAnalyst")}
                        </Typography>
                        <Typography>{t("chat.seeTheStory")}</Typography>
                    </Stack>
                )}
                {messages?.map((msg, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: "flex",
                            justifyContent:
                                msg.sender === "user"
                                    ? "flex-end"
                                    : "flex-start",
                            marginBottom: 1,
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: "70%",
                                padding: "8px 12px",
                                borderRadius: "16px",
                                wordBreak: "break-word", // Break long words
                                backgroundColor:
                                    msg.sender === "user"
                                        ? "#5BCFDE"
                                        : "#f1f1f1",
                                color: msg.sender === "user" ? "#fff" : "#000",
                                fontSize: "14px",
                            }}
                        >
                            <ReactMarkdown
                                children={preprocessMarkdown(msg.text)} // Preprocess Markdown
                                remarkPlugins={[remarkGfm, remarkMath]} // Enable tables and math
                                rehypePlugins={[rehypeKatex]} // Render LaTeX math
                                components={{
                                    p: ({ node, ...props }) => (
                                        <Typography
                                            {...props}
                                            sx={{ margin: "4px 0" }}
                                        />
                                    ),
                                    h1: ({ node, ...props }) => (
                                        <Typography
                                            {...props}
                                            variant="h6"
                                            sx={{
                                                fontWeight: "bold",
                                                marginBottom: "8px",
                                            }}
                                        />
                                    ),
                                    h2: ({ node, ...props }) => (
                                        <Typography
                                            {...props}
                                            variant="subtitle1"
                                            sx={{
                                                fontWeight: "medium",
                                                marginBottom: "6px",
                                            }}
                                        />
                                    ),
                                    table: ({ node, ...props }) => (
                                        <Box
                                            component="table"
                                            {...props}
                                            sx={{
                                                width: "100%",
                                                borderCollapse: "collapse",
                                                marginBottom: "16px",
                                            }}
                                        />
                                    ),
                                    th: ({ node, ...props }) => (
                                        <Box
                                            component="th"
                                            {...props}
                                            sx={{
                                                border: "1px solid #ddd",
                                                padding: "8px",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                backgroundColor: "#f9f9f9",
                                            }}
                                        />
                                    ),
                                    td: ({ node, ...props }) => (
                                        <Box
                                            component="td"
                                            {...props}
                                            sx={{
                                                border: "1px solid #ddd",
                                                padding: "8px",
                                                textAlign: "left",
                                            }}
                                        />
                                    ),
                                }}
                            />
                        </Box>
                    </Box>
                ))}
                {isTyping && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            marginBottom: 1,
                        }}
                    >
                        <TypingIndicator />{" "}
                        {/* Use the TypingIndicator component */}
                    </Box>
                )}
                {/* Reference to scroll into view */}
                <div ref={messagesEndRef} />

                {!messages.length && !isFetching && (
                    <PredefinedQuestions onSend={handleSendMessage} />
                )}
            </Box>

            <Box display="flex" alignItems="center" gap={2}>
                <ChatInput onSend={handleSendMessage} />
            </Box>
        </Stack>
    );
};
