import { Box, Stack, Typography, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"; // Import the Edit icon
import { InputBase } from "@mui/material";
import { useTranslation } from "react-i18next";

export const Sidebar = ({ chatData, onSelectChat, onAddChat }) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState(""); // State for search input
    const selectedChatSession = searchParams.get("chat_session");

    const handleChatClick = (chat) => {
        onSelectChat(chat); // Pass the selected chat to the parent component
        setSearchParams({ chat_session: chat.chat_session }); // Update the URL with the chat_session
    };

    // Filter chats based on search query
    const filteredChats = chatData.filter((chat) =>
        chat.chat[0].user_question
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
    );

    return (
        <Stack
            width="300px"
            sx={{
                height: "calc(100vh - 90px)", // Matches the height of the chat area
                backgroundColor: "#F9FAFB", // Matches the chat area background color
                color: "#000000", // Black text for readability
                borderRight: "1px solid #E5E7EB", // Subtle border to separate the sidebar
                display: "flex",
                flexDirection: "column",
            }}
        >
            {/* Search Bar */}
            <Box
                sx={{
                    position: "sticky", // Keeps the search bar fixed
                    top: 0, // Sticks to the top of the sidebar
                    backgroundColor: "#F9FAFB", // Same background as the sidebar
                    zIndex: 10, // Ensures it stays above the scrollable content
                    padding: "16px",
                    borderBottom: "1px solid #E5E7EB",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "8px 12px",
                        borderRadius: "8px",
                        backgroundColor: "#EDEEF0", // Light gray background
                    }}
                >
                    {/* Search Icon */}
                    <SearchIcon sx={{ color: "#9E9E9E", marginRight: "8px" }} />
                    {/* Search Input */}
                    <InputBase
                        placeholder={t("chat.searchChat")}
                        sx={{
                            flex: 1,
                            fontSize: "14px",
                        }}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    {/* Pencil Icon */}
                    <Tooltip title={t("chat.newChat")}>
                        <IconButton
                            sx={{
                                backgroundColor: "transparent",
                                borderRadius: "50%", // Circular button
                                marginLeft: "8px", // Space between the input and the icon
                                "&:hover": {
                                    backgroundColor: "#E0E3E7",
                                },
                            }}
                            onClick={() => {
                                setSearchParams({}); // Clear the search parameters
                                setSearchQuery(""); // Clear the search query input
                            }}
                        >
                            <EditOutlinedIcon sx={{ color: "#9E9E9E" }} />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>

            {/* Scrollable Chat List */}
            <Stack
                sx={{
                    overflowY: "auto", // Makes the chat list scrollable
                    flex: 1,
                    padding: "16px",
                }}
            >
                {filteredChats.map((chat) => (
                    <Box
                        key={chat.id}
                        onClick={() => handleChatClick(chat)}
                        sx={{
                            padding: "12px 16px",
                            borderRadius: "8px",
                            cursor: "pointer",
                            transition: "background-color 0.2s ease",
                            backgroundColor:
                                chat.chat_session === selectedChatSession
                                    ? "#EDEEF0" // Highlight color for the selected chat
                                    : "transparent",
                            "&:hover": {
                                backgroundColor: "#EDEEF0", // Subtle hover effect
                            },
                        }}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                overflow: "hidden", // Ensures text content doesn't overflow
                                whiteSpace: "nowrap", // Prevents wrapping to the next line
                                textOverflow: "ellipsis", // Adds "..." to truncated text
                            }}
                        >
                            {chat.chat[0].user_question}
                        </Typography>
                    </Box>
                ))}
                {filteredChats.length === 0 && (
                    <Typography
                        sx={{
                            textAlign: "center",
                            color: "#9E9E9E",
                            marginTop: "16px",
                        }}
                    >
                        {t("chat.emptyChats")}
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
};
