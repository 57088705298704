import { Box } from "@mui/material";

export const TypingIndicator = () => {
    return (
        <Box
            sx={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                "& span": {
                    display: "block",
                    width: "6px",
                    height: "6px",
                    backgroundColor: "#5BCFDE",
                    borderRadius: "50%",
                    animation: "typing 1s infinite",
                },
                "& span:nth-of-type(2)": {
                    animationDelay: "0.2s",
                },
                "& span:nth-of-type(3)": {
                    animationDelay: "0.4s",
                },
                "@keyframes typing": {
                    "0%, 60%, 100%": { opacity: 0.3 },
                    "30%": { opacity: 1 },
                },
            }}
        >
            <span></span>
            <span></span>
            <span></span>
        </Box>
    );
};
