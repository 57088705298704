import {
    Box,
    Divider,
    Stack,
    Typography,
    Select,
    MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import {
    CustomSelectOutline,
    GridCardFlatLabels,
} from "../../../../../common/components";
import { constants } from "../../../../../config/constants";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import StackedBarChart from "./StackedBarChart";
import SalesChartTabs from "../Tabs/SalesChartTabs";

const MultiMeetricChartByDate = ({ data, isCompare, isPDF }) => {
    const { t } = useTranslation();

    const [chartData, setChartData] = useState(null);

    const [bars, setBars] = useState([]);

    useEffect(() => {
        if (chartData?.length) {
            const barsSet = new Set();

            chartData.forEach((bar) => {
                Object.keys(bar).forEach((k) => {
                    if (k !== "name" && k !== "prev") barsSet.add(k);
                });
            });

            setBars(Array.from(barsSet));
        }
    }, [chartData]);

    const labelsData = useMemo(
        () =>
            bars.map((labelName, index) => ({
                name: labelName,
                fill: constants.pieColors[index],
            })),
        [bars]
    );

    const [meetricIndicator, setMeetricIndicator] = useState("total_sales");

    useEffect(() => {
        if (!data) return;
        setChartData(data[`${meetricIndicator}`]);
    }, [data, meetricIndicator]);

    const handleMeetricsUpdate = (v) => {
        setMeetricIndicator(v);

        setChartData(chartData);
    };

    return (
        <Stack>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                padding={2}
                height={0}
            >
                <Box />

                {!isPDF && (
                    <Box className="tabs" position="relative" top="-55px">
                        <SalesChartTabs onTabChange={handleMeetricsUpdate} />
                    </Box>
                )}
            </Box>

            <Stack>
                <GridCardFlatLabels
                    data={labelsData}
                    isFlat
                    withPercentage={false}
                />

                <Box width="100%" height="450px" px={6}>
                    <StackedBarChart data={chartData} withCompare={isCompare} />
                </Box>
            </Stack>
        </Stack>
    );
};

export { MultiMeetricChartByDate };
