import { Box } from "@mui/material";
import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { services } from "../services";
import { Sidebar } from "./components/SideBar";
import { ChatRoomContainer } from "./components/ChatRoom";

const BallurhAssistant = () => {
    const { data, refetch } = useQuery({
        queryKey: ["chatRooms"],
        queryFn: services.getChatRooms,
    });

    const chatRooms = useMemo(() => data?.data?.response || [], [data?.data]);

    const handleChatRoomSelect = (chatroom) => {};

    return (
        <Box display="flex">
            <Sidebar onSelectChat={handleChatRoomSelect} chatData={chatRooms} />

            <ChatRoomContainer refetchMessages={refetch} />
        </Box>
    );
};

export { BallurhAssistant };
