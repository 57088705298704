import React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledTab = styled(Tab)(({ theme }) => ({
    textTransform: "none",
    fontWeight: "400",
    fontSize: "14px", // Match font size as in the image
    color: "#000000", // Default black color for text
    minHeight: "26px", // Set tab height to match the image
    padding: "0px 18px", // Adjust padding
    minWidth: "auto",
    "&.Mui-selected": {
        backgroundColor: "#DCF2E6", // Updated greenish background color
        color: "#3E8F7E", // Updated green text color
        borderRadius: "6px",
    },
}));

function SalesTabs({ onTabChange }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        onTabChange(newValue);
    };

    return (
        <Box>
            <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{ style: { display: "none" } }} // Hide the default indicator
                sx={{
                    borderBottom: "none", // Remove bottom border
                    minHeight: "32px", // Match height
                }}
            >
                <StyledTab label="Branch" />
                <StyledTab label="Day" />
            </Tabs>
        </Box>
    );
}

export default SalesTabs;
