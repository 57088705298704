import { useState } from "react";
import { Stack, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

import CustomTabs from "./Tabs";
import { PL } from "./pages/PL";
import { Operations } from "./pages/Operations";
import { Sales } from "./pages/Sales";

const SalesAggregators = () => {
    const { t } = useTranslation();

    const [tab, setTab] = useState(0);

    const handleTabChange = (newTab) => {
        setTab(newTab);
    };

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <Stack spacing={0}>
                <Typography variant="h5">{t("sales.delivery")}</Typography>
            </Stack>

            <CustomTabs onTabChange={handleTabChange} />

            {tab === 0 && <Sales />}
            {tab === 1 && <Operations />}
            {tab === 2 && <PL />}
        </Stack>
    );
};

export { SalesAggregators };
