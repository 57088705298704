import { constants } from "../../../../config/constants";
import Http from "../../../../config/http";

const services = {
    getSalesAggregatorsCards: (body) =>
        Http.POST(
            `${constants.API}/api/v1/delivery-sales/cards/
`,
            body
        ),
    getSalesAggregatorsBranch: (body) =>
        Http.POST(
            `${constants.API}/api/v1/delivery-sales/charts/branch/
`,
            body
        ),
    getSalesAggregatorsDay: (body) =>
        Http.POST(
            `${constants.API}/api/v1/delivery-sales/charts/interval/
`,
            body
        ),
    getSalesAggregatorsOperations: (body) =>
        Http.POST(
            `${constants.API}/api/v1/delivery-sales/operations/
`,
            body
        ),
};

export { services };
