import Http from "../../../config/http";
import { constants } from "../../../config/constants";

const services = {
    getChatRooms: () => Http.GET(`${constants.API}/api/v1/chat-request/`),
    getChatRoom: (chatSession) => {
        const id = chatSession?.queryKey[1]?.chatSession;
        return Http.GET(`${constants.API}/api/v1/chat-request/${id}/`);
    },
    getMenuEngineeringProducts: (body) =>
        Http.POST(`${constants.API}/api/v1/menu-engineering/`, body),
    getMenuProduct: (product) =>
        Http.POST(`${constants.API}/api/v1/product-info/`, product),
    getCustomer: (customer) =>
        Http.POST(`${constants.API}/api/v1/customer-details/`, customer),
    getOverviewData: (body) =>
        Http.POST(`${constants.API}/api/v1/overview/`, body),
    getForcastingData: (body) =>
        Http.POST(`${constants.API}/api/v1/forecasting/`, body),
    getSalesGoal: (body) => {
        return Http.POST(`${constants.API}/api/v1/sales-goal/`, body);
    },
    getMostFrequent: (body) =>
        Http.POST(`${constants.API}/api/v1/most-frequent/`, body),
    getMerchantProducts: () =>
        Http.GET(`${constants.API}/api/v1/merchant-products`),
    getMerchantChannels: () =>
        Http.GET(`${constants.API}/api/v1/merchant-channels`),
    getMerchantCategories: () =>
        Http.GET(`${constants.API}/api/v1/merchant-categories`),
    getBasketProduct: (body) =>
        Http.POST(`${constants.API}/api/v1/product-analysis/`, body),
    getCustomerInsights: (body) =>
        Http.POST(`${constants.API}/api/v1/customer-insights/`, body),
    getCustomersData: (body) =>
        Http.POST(`${constants.API}/api/v1/customers-data/`, body),
    getSalesInsights: (body) =>
        Http.POST(`${constants.API}/api/v1/sales-insights/`, body),
    getSalesPerformance: (body) =>
        Http.POST(`${constants.API}/api/v1/sales-performance/`, body),
    getSalesAggregators: (body) =>
        Http.POST(`${constants.API}/api/v1/sales-aggregator/`, body),
    getProductLikeToLike: (body) =>
        Http.POST(`${constants.API}/api/v1/product-like-to-like/`, body),
    getCategoryLikeToLike: (body) =>
        Http.POST(`${constants.API}/api/v1/category-like-to-like/`, body),
    getBranchLikeToLike: (body) =>
        Http.POST(`${constants.API}/api/v1/branch-like-to-like/`, body),
    getManagers: () => Http.GET(`${constants.API}/auth/my-team`),
    getOwners: () => Http.GET(`${constants.API}/auth/group-team`),
    createManager: (body) => Http.POST(`${constants.API}/auth/my-team`, body),
    createOwner: (body) => Http.POST(`${constants.API}/auth/group-team`, body),
    updateManager: (body) => Http.PUT(`${constants.API}/auth/my-team`, body),
    updateOwner: (body) => Http.PUT(`${constants.API}/auth/group-team`, body),
    updateBranch: (body) =>
        Http.PUT(`${constants.API}/api/v1/merchant-branchs`, body),
    addBranch: (body) =>
        Http.POST(`${constants.API}/api/v1/merchant-branchs`, body),
    getBranches: (body) => Http.GET(`${constants.API}/api/v1/merchant-branchs`),
    deleteOwner: (id) =>
        Http.DELETE(`${constants.API}/auth/group-team`, { id }),
    deleteManager: (id) => Http.DELETE(`${constants.API}/auth/my-team`, { id }),
    updateCurrentProfile: (body) =>
        Http.PATCH(`${constants.API}/api/v1/profile/`, body),
    updateWorkingHours: (body) =>
        Http.POST(`${constants.API}/api/v1/merchant-branchs`, body),
    resyncData: () => Http.POST(`${constants.API}/api/v1/load-data/`),
    exportPixelAudience: () =>
        Http.POST(`${constants.API}/api/v1/export-audience/`, {}),
    exportPixelEvents: (body) =>
        Http.POST(`${constants.API}/api/v1/export-events/`, body),
};

export { services };
