import { getToken } from "../../../common/utils";
import { constants } from "../../../config/constants";

function transformToMessages(chatArray) {
    const messages = [];

    chatArray.forEach((chat) => {
        // Push the user's question
        messages.push({
            sender: "user",
            text: chat.user_question,
        });

        // Push the bot's response
        messages.push({
            sender: "bot",
            text:
                chat?.response?.output ||
                chat?.response?.error ||
                "Failed to get a valid response from the chat app",
        });
    });

    return messages;
}

async function queryDatabase(question, merchantDetails) {
    const { merchant_name, merchant_id, merchant_key, chat_session } =
        merchantDetails;

    const session = getToken();

    const response = await fetch(
        `${constants.baseURL}/ballurh/api/v1/chat-request/`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${session}`,
            },
            body: JSON.stringify({
                user_question: question,
                merchant_name,
                merchant_id,
                merchant_key,
                chat_session,
            }),
        }
    );

    if (!response.body) {
        throw new Error("Readable stream is not supported");
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let result = "";

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        result += decoder.decode(value, { stream: true });
        // Here you can update your state to display the response incrementally
    }

    return result;
}

function preprocessMarkdown(input) {
    return input.replace(/\[\s*([^[]+?)\s*\]/g, "\\[$1\\]");
}

export { preprocessMarkdown, queryDatabase, transformToMessages };
